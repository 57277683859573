<div class="modal-header">
    <h4 class="modal-title">Editar Usuario</h4>
    <button type="button" class="close" aria-label="Close" (click)="cerrar.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form>
    <div class="modal-body">
        <div class="col-12">
            <label for="username">Username</label>
            <input class="form-control" id="username" name="username" [(ngModel)]="user.username"
                placeholder="Username">
        </div>
        <div class="col-12">
            <label for="nombre">Nombre</label>
            <input class="form-control" id="nombre" name="nombre" [(ngModel)]="user.nombre"
                placeholder="Nombre">
        </div>
        <div class="col-12">
            <label for="cargo">Cargo</label>
            <input class="form-control" id="cargo" name="cargo" [(ngModel)]="user.cargo"
                placeholder="Cargo">
        </div>
        <div class="col-12 mt-2 mb-2">
            <input type="checkbox" class="mr-2" id="changePassword" name="changePassword" [(ngModel)]="changePassword">
            <label for="changePassword">Contraseña</label>
        </div>
        <div *ngIf="changePassword" [ngbCollapse]="!changePassword">
            <div *ngIf="changePassword" [ngbCollapse]="!changePassword">
                <div class="col-12 mb-2">
                    <label for="password">Contraseña</label>
                    <input type="password" class="form-control" id="new-password" name="new-password"
                        placeholder="Contraseña" [(ngModel)]="passwords.pass1">
                </div>
                <div class="col-12 mb-2">
                    <input type="password" class="form-control" id="repeat" name="repeat"
                        placeholder="Repetir Contraseña" [(ngModel)]="passwords.pass2">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex">
            <button type="submit" class="btn btn-primary justify-content-end"
                (click)="save()">Guardar</button>
        </div>
    </div>
</form>