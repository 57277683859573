<div class="card mb-3">
    <div class="card-body">
        <h5 class="card-title">Busqueda Avanzada</h5>
        <form #formSearchFicha="ngForm" (ngSubmit)="submit()">
            <div class="form-row">
                <div class="form-group col-lg-3 col-md-6">
                    <label for="dni">RUT</label>
                    <input type="text" class="form-control" id="dni" name="dni" [(ngModel)]="fichaFilter.traRut">
                </div>
                <!-- <div class="form-group col-lg-3 col-md-6">
                    <label for="name">Nombre</label>
                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="fichaFilter.traNombre">
                </div>
                <div class="form-group col-lg-3 col-md-6">
                    <label for="name">Apellido</label>
                    <input type="text" class="form-control" id="lastName" name="lastName"
                        [(ngModel)]="fichaFilter.traPaterno">
                </div>

                <div class="form-group col-lg-3 col-md-6">
                    <label for="name">Evaluación</label>
                    <ng-select name="evaluaciones" [items]="evaluaciones" [multiple]="true" [closeOnSelect]="false"
                        [searchable]="false" bindValue="eva_id" [(ngModel)]="fichaFilter.evaId">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item">
                            {{'EVALUATION.EVA_'+item.eva_id}}</ng-template>
                    </ng-select>
                </div> -->

            </div>

            <button type="reset" class="btn btn-secondary  float-right" (click)="search.emit({})">
                <fa-icon [icon]="['fas','eraser']"></fa-icon> Limpiar
            </button>
            <button type="submit" class="btn btn-primary mr-3 float-right">
                <fa-icon [icon]="['fas','search']"></fa-icon> Buscar
            </button>
        </form>
    </div>
</div>