<div class="container-fluid">
    <div class="row">
        <div class="col">
            <img src="/assets/img/logo-softys.png" height="65" class="rounded float-left" alt="...">
        </div>
    </div>
</div>
<nav class="navbar navbar-expand-sm navbar-dark bg-primary">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="[]">Entrenamiento Virtual</a>
        <button (click)="isMenuCollapsed = !isMenuCollapsed" class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" [ngbCollapse]="isMenuCollapsed" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item" ngbDropdown>
                    <a class="nav-link" ngbDropdownToggle id="navbarDropdown2" role="button">
                        <fa-icon [icon]="['fas','user']"></fa-icon> {{username}}
                    </a>
                    <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-right">
                        <a ngbDropdownItem [routerLink]="" (click)="open(userEdit)">
                            <fa-icon [icon]="['fas','user-edit']"></fa-icon> Perfil
                        </a>
                        <div class="dropdown-divider"></div>
                        <a ngbDropdownItem [routerLink]="" (click)="logout()">
                            <fa-icon [icon]="['fas','sign-out-alt']"></fa-icon> Cerrar Sesión
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<ng-template #userEdit>
    <app-profile [user]="user" (cerrar)="close()" (guardar)="guardar($event)"></app-profile>
</ng-template>