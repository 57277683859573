import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Contratista } from '../../_class/contratista';
import { Evaluacion } from '../../_class/evaluacion';
import { Ficha } from '../../_class/ficha';
import { FichaFilter } from '../../_class/filter/ficha-filter';
import { Pagination } from '../../_class/pagination';
import { AuthService } from '../../_services/auth.service';
import { FichaParamsService } from '../../_services/ficha-params.service';
import { ReportService } from '../../_services/report.service';
import { TrabajadorService } from '../../_services/trabajador.service';
import { TransversalService } from '../../_services/transversal.service';

@Component({
  selector: 'app-admin-ficha',
  templateUrl: './admin-ficha.component.html',
  styleUrls: ['./admin-ficha.component.scss']
})
export class AdminFichaComponent implements OnInit {

  collapseSearch = true;

  models: Ficha[];

  evaluaciones: Evaluacion[];

  modalRef: NgbModalRef;

  page: Pagination = new Pagination({
    total: 1,
    page: 1,
    perPage: 50,
    sort: '-fic_id',
    expand: ['trabajador', 'params', 'evaluacion']
  });
  // Toastr Messages
  toastr = {
    saving: "guardando...",
    updatedInfo: "información actualizada",
    searchInfo: "Información filtrada",
    cleanInfo: "Información re-establecida",
    dontsave: "no se guardaron los cambios",
    error: "error",
    generatingPdf: "generando PDF...",
    success: "exito",
    pdfSuccess: "PDF se ha generado exitosamente",
    fail: "ha ocurrido un error",
    internetConnection: "Ha ocurrido un problema, verifique la conexión a internet",
    downloadReport: "descargando reporte masivo...",
    reportSuccess: "se ha descargado reporte masivo correctamente",
    scrollEnd: "Ha llegado al final"
  }

  //#region Sorteable Table
  sort = {
    name: 'fic_id',
    order: false,
    menu: [
      { sort: 'ficId', title: 'Ficha' },
      { sort: 'traRut', title: 'RUT' },
      { sort: 'params.data.dec_correcto', title: 'CANT PERCEPCION'},
      { sort: 'params.data.dec_total', title: 'PERCEPCION TOTAL' },
      { sort: 'params.data.dec_nota', title: 'PERCEPCION NOTA ' },
      { sort: 'params.data.con_correcto', title: 'CANT CONDUCTUAL' },
      { sort: 'params.data.con_total', title: 'CONDUCTUAL TOTAL' },
      { sort: 'params.data.con_nota', title: 'CONDUCTUAL NOTA' },
      { sort: 'params.data.nota', title: 'NOTA' }
    ]
  };

  toastrFlags = {
    reload: false,
    search: false,
    clean: false
  }


  fichaFilter: FichaFilter = new FichaFilter();

  constructor(
    private sessionService: AuthService,
    private modal: NgbModal,
    private trabajadorService: TrabajadorService,
    private toast: ToastrService,
    private reportService: ReportService,
    private fichaParamsService: FichaParamsService,
    private transversalService: TransversalService
  ) { }

  ngOnInit(): void {
    if (this.sessionService.isValid) {
      this.getFirstData()
    }
    this.sessionService.onLoginSuccess.subscribe(({ name }) => {
      this.getFirstData()

    });

    
  }

  async getFirstData() {
    this.page.page = 1;
    this.page.perPage = 20;
    const { headers, body } = await this.transversalService.GetFichas(this.page.params).toPromise();
    this.page.Load(headers);
    this.models = body;

    this.evaluaciones = await this.transversalService.GetEvaluaciones().toPromise();
  }

  async onScroll() {
    if (this.models.length < this.page.total) {
      this.page.page++;
      const { body, headers } = await this.transversalService.GetFichas(this.page.params).toPromise();
      this.page.Load(headers);
      this.models.push(...body);
    } else {
      this.toast.info(this.toastr.scrollEnd);
    }
  }

  setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop;
      this.sort.order = true;
    } else {
      this.sort.order = !this.sort.order;
    }
    this.page.page = 1;
    this.page.sort = this.sort.order ? this.sort.name : '-' + this.sort.name
    this.getFirstData()
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? ['fas', 'sort-down'] : ['fas', 'sort-up'];
    } else {
      return ['fas', 'sort'];
    }
  }

  ficha: Ficha;
  open(content, ficha: Ficha) {
    this.ficha = ficha;
    this.modalRef = this.modal.open(content, { size: 'lg' });
  }

  close() {
    this.modalRef.close();
  }

  getPdf(ficha) {
    const toastrRef = this.toast.info(this.toastr.generatingPdf, 'PDF');
    this.reportService.getFichaById(ficha)
      .subscribe(
        response => {
          const a = document.createElement('a');
          const filePDF = new Blob([response], { type: 'application/pdf' });
          const fileURL = window.URL.createObjectURL(filePDF);
          a.href = fileURL;
          a.download = 'REP SEG ' + ficha + '.pdf';
          a.click();
          this.toast.remove(toastrRef.toastId);
          this.toast.success(this.toastr.pdfSuccess, 'PDF');
        }, () => {
          this.toast.remove(toastrRef.toastId);
          this.toast.error(this.toastr.fail, 'PDF');
        });
  }

  trackBy(index: number, item: Ficha): number {
    return item.fic_id;
  }

  async updateFicha(model) {
    var { trabajador } = model;
    try {
      this.modalRef.close();
      this.ficha.trabajador = await this.trabajadorService.Update(trabajador.tra_id, trabajador).toPromise();
      this.toast.success(this.toastr.updatedInfo);
    } catch (error) {
      this.toast.error(this.toastr.dontsave, this.toastr.error)
    }
  }

  async search(criteria: FichaFilter) {
    var params = new HttpParams();
    if (criteria.traRut) {
      params = params.set('filter[traRut][like]', criteria.traRut)
    }
    if (criteria.traNombre) {
      params = params.set('filter[traNombre][like]', criteria.traNombre)
    }
    if (criteria.traPaterno) {
      params = params.set('filter[traPaterno][like]', criteria.traPaterno)
    }
    if (criteria.evaId) {
      params = criteria.evaId.reduce((acc, cur) => acc.append("filter[evaId][in][]", cur.toString()), params)
    }
    console.log(params.toString())
    this.page.params = params;
    this.getFirstData();
  }

  init(){
    if (this.sessionService.isValid) {
      this.getFirstData()
      this.toast.success(this.toastr.updatedInfo, this.toastr.success)
    }
  }
}
